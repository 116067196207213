export const menuItems = [
    {
        title: 'Services',
        url: '/service',
        cName:'links-li'
    },
  
    {
        title: 'About us',
        url: '/about',
        cName:'links-li'
    },
]